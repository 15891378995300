/**
 * Created by Sergey Panpurin on 8/16/2018.
 */

(function btVideoPopupClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * This directive show video popup
     *
     * @ngdoc directive
     * @name btVideoPopup
     * @memberOf dashboard
     */
    .directive('btVideoPopup', directive);

  directive.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function directive($templateCache) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        video: '=',
      },
      template: $templateCache.get('dashboard/directives/common/bt-video-popup.html'),
      controller: controller,
      link: function link(scope, element, attrs) {
        // https://github.com/desandro/draggabilly
        var $draggable = element.draggabilly({
          handle: '#bt-video-popup-handle',
          containment: '#bt-main-container',
          grid: [5, 5],
        });

        scope.$on('$destroy', function () {
          $draggable.draggabilly('destroy');
        });
      },
    };
  }

  controller.$inject = ['$scope', '$sce', 'btSettingsService', 'btYouTubeService', 'btShareScopeService'];

  /**
   *
   * @param {*} $scope
   * @param {angular.ISCEService} $sce
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IYouTubeService} btYouTubeService
   * @param {ecapp.IShareScopeService} btShareScopeService
   */
  function controller($scope, $sce, btSettingsService, btYouTubeService, btShareScopeService) {
    var gSettings = btSettingsService.getLocalSettings();

    $scope.channels = [];
    $scope.selected = null;

    $scope.error = '';
    $scope.isLoading = true;

    $scope.minimized = false;
    $scope.minimize = minimize;
    $scope.close = close;
    $scope.select = select;

    btShareScopeService
      .getAppSettings('live-news')
      .then(function (result) {
        $scope.isLoading = false;

        result.channels.forEach(function (channel) {
          channel.trustedUrl = $sce.trustAsResourceUrl(channel.url);
        });

        $scope.channels = result.channels.filter(function (channel) {
          return channel.enabled;
        });

        var channel = $scope.channels.filter(function (value) {
          return value.id === gSettings.get('live-news.channel');
        })[0];

        $scope.selected = channel || $scope.channels[0];
      })
      .catch(function (error) {
        $scope.isLoading = false;
        $scope.error = 'Unexpected error';
        console.error(error);
      });

    btYouTubeService.init().then(function (YT) {});

    /**
     *
     */
    function minimize() {
      $scope.minimized = !$scope.minimized;
    }

    /**
     *
     */
    function close() {
      $scope.$parent.closeTvPopup();
    }

    /**
     *
     */
    function select() {
      gSettings.set('live-news.channel', $scope.selected.id);
    }
  }
})();
