(function btNewsCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btTwitterCtrl', btTwitterCtrl);

  btTwitterCtrl.$inject = [
    '$scope',
    '$q',
    '$state',
    '$timeout',
    'btTwitterScannerService',
    'btToastrService',
    'btSettingsService',
  ];

  /**
   * @typedef {angular.IScope} btTwitterDeckScope
   * @property {number} hasWidget - ?
   * @property {boolean} isLoading - ?
   * @property {number} totalLimit - ?
   * @property {btCustomTwitterAccount[]} channels - ?
   * @property {function} openTwitterTour - ?
   * @property {function} openTwitterScanner - ?
   * @property {function} openCaseStudy - ?
   * @property {function} addNewChannel - ?
   */

  /**
   * Twitter controller
   *
   * @ngdoc controller
   * @name btTwitterCtrl
   * @memberOf dashboard
   * @param {btTwitterDeckScope} $scope
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ui.IStateService} $state
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ITwitterScannerService} btTwitterScannerService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btTwitterCtrl($scope, $q, $state, $timeout, btTwitterScannerService, btToastrService, btSettingsService) {
    console.log('Running btTwitterCtrl');

    $scope.isBigBrainBank = btSettingsService.getDomain() === 'bigbrainbank';

    $scope.hasWidget = 1;
    $scope.isLoading = true;
    $scope.channels = [];
    $scope.totalLimit = btTwitterScannerService.getTotalLimit();

    $scope.openTwitterTour = openTwitterTour;
    $scope.openTwitterScanner = openTwitterScanner;
    $scope.openTutorial = openTutorial;
    $scope.openCaseStudy = openCaseStudy;
    $scope.addNewChannel = addNewChannel;

    activate();

    /**
     * This function activates controller.
     */
    function activate() {
      btTwitterScannerService
        .initialize()
        .then(function () {
          $scope.channels = btTwitterScannerService.list().filter(channel => channel.id);
          $scope.isLoading = false;
        })
        .catch(function (reason) {
          console.error(reason);
        });
    }

    /**
     * This function shows Twitter Scanner tour.
     */
    function openTwitterTour() {}

    /**
     * This function open Twitter Scanner settings in left side.
     */
    function openTwitterScanner() {
      $state.go('ecapp.app.twitter-scanner');
    }

    /**
     * This function open Twitter Scanner tutorial.
     */
    function openTutorial() {
      window.open('https://youtu.be/RnnaIc96TUM', '_system');
    }

    /**
     *
     */
    function openCaseStudy() {
      window.open(
        'https://bettertrader.co/case-studies/how-trumps-tweets-influence-the-oil-market-in-real-time.html',
        '_system'
      );
    }

    /**
     *
     */
    function addNewChannel() {
      btTwitterScannerService
        .addNewChannel()
        .then(function (channel) {
          if (channel) {
            var msg = 'Channel ' + channel.name + ' was added to your list. Open Twitter Scanner for more settings.';
            btToastrService.success(msg, 'Twitter Scanner', {
              onTap: function () {
                $state.go('ecapp.app.twitter-scanner');
              },
            });
          }
        })
        .catch(function (reason) {
          console.error(reason);
          btToastrService.error('Adding failed! ' + (reason.message || 'Unknown error.'), 'Twitter Scanner');
        });
    }
  }
})();
